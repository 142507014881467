<template>
  <div class="wrapper">
    <WorkInProgress feature="Leave Overview" />
  </div>
</template>

<script>
import WorkInProgress from "@/components/common/state/WorkInProgress.vue";

export default {
  name: "LeaveOverview",

  components: { WorkInProgress },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: calc(100vh - 208px);
}
</style>
